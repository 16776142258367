@font-face {
    font-family: "alqalam ishtiaq";
    src: url(/static/media/alqalam-ishtiaq.cec8b31f.otf);
    font-weight: normal;
font-style: normal;
}
@font-face {
    font-family: "Al Rashed Riyadh";
    src: url(/static/media/Al-Rashed-Riyadh.e3270667.TTF);
    font-weight: normal;
font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue Light";
    src: url(/static/media/HelveticaNeue-Light.abaac753.otf);
    font-weight: normal;
font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue Medium";
    src: url(/static/media/helvetica-neue-medium.9f25b1f8.ttf);
    font-weight: normal;
font-style: normal;
}
@font-face {
    font-family: "Helvetica Neue Thin";
    src: url(/static/media/helvetica-neue-ce-35-thin.1570d2f0.ttf);
  

}
/* ===================================
    DEFAUT VALUES:  
    Font Family  :   Helvetica Neue Light
    Greenish Blue:   #34c6d3 (button,Icons,Link Lines & backgrounds)
    steel Gray   :   #41464b (Headings)
    Blue Bayoux  :   #000 (paragraph)
    White        :   #fff    (Text with black Background)
    Black        :   #000
======================================*/

/* ===================================
        General CSS
======================================*/
.backToTop {
    position: fixed;
    /* Fixed/sticky position */
    bottom: 20px;
    /* Place the button at the bottom of the page */
    right: 0;
    /* Place the button 30px from the right */
    z-index: 99;
    /* Make sure it does not overlap */
    border: none;
    /* Remove borders */
    outline: none;
    /* Remove outline */

    cursor: pointer;
    /* Add a mouse pointer on hover */
    padding: 15px;
    /* Some padding */
}

.loading-component {
    background-color: #F7F7F7;
    height: 100vh;
    z-index: 9;
    top: 0;
    position: absolute;

}

.loading-content {
    height: 100%;
}

.thumbnail-slider-wrap {
    margin-top: 15px;
    height: 100px;

}

.thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
}

.thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 94%;
}

html,
html,
body,
#root,
.App {
    height: 100%;
}

body {
    font-family: "Helvetica Neue Light";
    color: #000;
    position: relative;
}

p {
    font-family: "Helvetica Neue Light";
    font-size: 16px;
    font-weight: 300;
    color: #000;
}

.btn:focus {
    box-shadow: transparent !important;
}

.btn-success {
    background-color: #00471b !important;
    font-size: 30px !important;
    color: #fff;
    border: 0;
    border-radius: 15px !important;
}

.btn-primary {
    background-color: #980002 !important;
    font-size: 30px !important;
    color: #fff;
    border: 0;
    border-radius: 15px !important;
}

.btn-primary-sm,
.btn-primary-sm:hover {
    background-color: #980002 !important;
    font-size: 10px !important;
    color: #fff;
    border: 0;
    border-radius: 15px !important;
}

.btn-primary-md {
    background-color: #980002 !important;
    font-size: 15px !important;
    color: #fff;
    border: 0;
    border-radius: 0 !important;
}

h3 {
    color: #980002;
    font-size: 19px;
    text-transform: uppercase;
    font-family: "Helvetica Neue Light";
}

h2 {
    font-family: "Helvetica Neue Medium";
    font-size: 28px;
    color: #000;
}

#home {
    height: 100%;
}

/* ===================================
       Content General
======================================*/

.content-box {
    padding: 120px 0 60px 0;
}

.content-title-black h1 {
    font-size: 48px;
    text-align: center;
    margin: 0 0 30px 0;
}

.content-title-White h1 {
    color: #fff;
    font-size: 48px;
    text-align: center;
    margin: 0 0 30px 0;
}

.dropzone {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #fff;
    border-style: dashed;
    background-color: #980002;
    color: #fff;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone>p {
    color: #fff;
}

/* ===================================
            Navigator Menu  CSS
======================================*/

.navbar {
    padding: 20px 0;
    transition: all .5s ease-in-out;
}

.rado-top-nav {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 7px 0;
}

.navbar-nav {
    float: right;
}

.nav-link {
    color: #fff;
    font-size: 14px;
    font-family: "sans-serif";
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    cursor: pointer;
}

.active>.nav-link {
    color: #980002;
}

.nav-link:hover,
.nav-link:active {
    color: #980002;
    text-decoration: none;
}

.nav-item>a:hover {
    color: #980002;
    text-decoration: none;
}

.nav-lang-item {
    color: #fff;
    font-size: 14px;
    font-family: "alqalam ishtiaq";
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    cursor: pointer;
}

.selected-nav-lang-item {
    color: #980002;
}

.page-link {
    color: #fff;
    font-size: 14px;
    background-color: #980002;
    border-color: #980002;
    cursor: pointer;
}

.page-item.active .page-link {
    color: #fff;
    font-size: 14px;
    background-color: #000;
    border-color: #980002;
    cursor: pointer;
}

.page-link:hover {
    color: #980002;
    background-color: #000;
    border-color: #980002;
}

/* ===================================
       HOME General
======================================*/
#home {
    height: 100%;
}

#home-cover {
    height: 100%;
    background: url(/static/media/bg-home.db58d7da.jpg);

    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

}


#home-content-box {
    width: 100%;
    height: 100%;
    display: table;
}

#home-content-box-inner {
    display: table-cell;
    vertical-align: middle;
}

.slogan {
    border-left: 15px solid #980002;
    align-items: center;

    margin-top: 270px;


}

.slogan h3 {
    font-family: "Helvetica Neue Light";
    color: #fff;
    font-size: 27px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 3px 0 0 0;
}

.slogan h4 {
    font-family: "Helvetica Neue Light";
    color: #fff;
    font-size: 21px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 6px 0 4px 0;
    padding: 10px 0 10px 10px;
    background-color: rgb(181, 0, 4, 0.2);
}

.whatsapp-call {

    background-color: rgb(181, 0, 4, 0.2);
    height: 57px;
    width: 204px;

}

.whatsapp-call:hover {
    text-decoration: none;
}

.call-us-via {
    margin-top: 210px;
    margin-bottom: 103px;

}

.call-ref {
    text-align: left;
    padding: 15px;
}

.call-ref>p {
    font-family: "Helvetica Neue Light";
    color: #fff;
    font-size: 16px;
    margin: 0;


}

.call-icon {
    background-color: #980002;
    padding: 5px;

}

.call-icon img {
    width: 51px;
    height: 51px;
}

/* ===================================
       Button General
======================================*/
.btn-general {
    font-family: "Helvetica Neue Light";
    color: #fff;
    border-width: 2px;
    border-radius: 15px;
    padding: 8px 20px 8px 20px;
    font-size: 29px;
    text-transform: uppercase;
}

.btn-gray {
    font-family: "Helvetica Neue Light";
    color: #4F4F4F;
    background-color: #CCCCCC;
    border-width: 2px;
    border-radius: 0;
    padding: 10px 0;
    font-size: 14px;
    width: 30%;
}

.btn-gray:hover {
    background-color: #980002;
    color: #fff;
}

.btn-red {
    color: #fff;
    background-color: #980002;
}

.btn-red:hover {
    background-color: #fff;
    color: #980002;
}

/* ===================================
       About Us
======================================*/
.embed-responsive {
    border-bottom: 15px solid #980002;
    margin-bottom: 15px;
}

.about-us-text-item {
    border-left: 11px solid #980002;
    margin-bottom: 20px;
    padding-left: 15px;
}

.about-us-img img {
    width: 100%;
}

/* ===================================
       Service
======================================*/
#services {
    background: linear-gradient(90deg, #980002 30%, #000 30%);

}

.our-service-content {
    width: 870px;
}

.our-service-card {
    background-color: #000;
    padding: 15px 6px;
    color: #fff;
}

.our-service-text h2 {
    color: #fff;
    margin-top: 35px;
}

.our-service-text p {
    color: #fff;
}

/* ===================================
          Sell your Car
======================================*/
#sell-your-car {
    margin-top: 64px;
    background: linear-gradient(90deg, #000 30%, #980002 30%);
    height: 100%;

}

.lf-sell-car {
    height: 100%;
    background-image: url(/static/media/lf-sell-car-.17ffa3a0.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.lf-sell-car-inner {
    background-image: linear-gradient(45deg, rgba(152, 0, 2, 0.3897934173669467) 60%, rgba(152, 0, 2, 0) 60%);

    height: 100%;

}

.lf-sell-car-inner h2 {

    color: #fff;
    padding-top: 50px;
    padding-right: 70%;
    padding-left: 15px;
}

.lf-sell-car-inner h3 {
    padding-top: 50px;
    padding-right: 30%;
    padding-left: 15px;
    color: #fff;
}

.sell-your-car-card {
    background: #fff;
    padding: 12px;
}

.sell-your-car-card img {
    border-left: 10px solid #980002;
    width: 100%;
}

.sell-car-input:focus {
    border-color: #980002 !important;
    box-shadow: 0 0 0 0.2rem rgb(152 0 2 / 25%) !important;
}

.sell-car-input {
    border-color: #000 !important;
}

.sell-content-title {
    border-bottom: 1px solid #980002;
    margin-bottom: 10px;
}

#why-us {


    margin: 64px 0;
}

.why-us-bg {

    /*background: linear-gradient(315deg, rgba(0, 0, 0, 1) 63%, rgba(255, 255, 255, 1) 63%);*/
    margin-top: 65px;
    padding: 15px 35px;
}

.why-we-options {
    border: 1px solid #980002;
    padding: 15px 30px;
    margin: 64px 0;
    background-color: #fff
}

.why-we-option-title {
    background-color: #000;
    color: #fff;
    width: 97px;
    height: 97px;
    border-radius: 50%;
    display: flex;
    /* or inline-flex */
    align-items: center;
    justify-content: center;
    margin-top: -21%;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.why-we-options img,
.why-we-options>p {
    margin: 15px;
}

.why-we-card-title {

    font-size: 18px;
    font-family: "Arial";
    color: #980002;
    padding: 10px 0 0 3px;
    font-weight: 400;
    margin-bottom: 0.25rem !important;
    text-transform: uppercase;
}

#contact {
    background: rgb(0, 0, 0);
    background: linear-gradient(373deg, rgba(0, 0, 0, 0.06766456582633051) 53%, rgba(255, 255, 255, 0) 53%);
}

.contact-info {
    background-color: #B50004;
    padding: 50px 0px;

}

.contact-info-item {
    padding: 25px;

}

.contact-info-item h4 {
    color: #fff;
    font-weight: 700;
    font-size: 16px;

}

.contact-info-item p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
}

.contact-info-item a {
    color: #fff;
    font-size: 14px;
}

.contact-us-bar {
    border-top: 45px solid transparent;
    border-right: 64px solid #980002;
}

footer {
    background-color: #000;
    padding-top: 50px;
}

.foooter-content-title {
    color: #fff;
    font-size: 22px;
    font-family: "alqalam ishtiaq";
    font-weight: bold;
    margin-bottom: 10px;
}

footer .nav-link {
    color: #808080;
    font-size: 14px;
    font-family: "Helvetica Neue Medium";
    padding-left: 0;

}

footer .nav-link:hover {
    color: #fff;
    font-size: 14px;

}

.foooter-content p {
    color: #808080;
    font-size: 14px;
}

.footer-address-content,
.footer-address-content a {
    color: #808080;
    font-size: 14px;
    font-family: "Helvetica Neue Medium";

}

.footer-address-content-tel>a:hover {
    color: #fff;
    text-decoration: none;
}

.nav-open-hour {
    color: #808080;
    font-size: 14px;
    font-family: "Helvetica Neue Medium";
}

.butoom-footer {
    background-color: #980002;
}

.copyright {
    color: #808080;
    font-size: 14px;
    font-family: "Helvetica Neue Medium";
    vertical-align: middle;
}

#our-cars {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 28%,
            rgba(0, 0, 0, 1) 28%, rgba(7, 7, 7, 1) 70%, rgba(255, 255, 255, 1) 70%)
}

.our-car-item {
    background-color: transparent !important;
}

.list-group-item {
    background-color: transparent !important;
    padding-top: 5px;
    border: 0 !important;
}

.card-body {
    border-top: 5px solid #980002;
    background: linear-gradient(135deg, rgba(255, 255, 255, 1) 87%, #c4bab000 88%);
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0.30rem !important;
    padding-top: 0.30rem !important;
    box-shadow: -34px 47px 16px -31px rgba(0, 0, 0, 0.27);
    -webkit-box-shadow: -34px 47px 16px -31px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: -34px 47px 16px -31px rgba(0, 0, 0, 0.27);
}

.card-title {
    font-size: 18px;
    font-family: "alqalam ishtiaq";
    border-left: 4px solid #980002;
    padding: 10px 0 0 3px;
    font-weight: 400;
    margin-bottom: 0.25rem !important;
    height: 50px;

}

.car-image:hover+.card-compare-icon {
    display: block;
    position: absolute;
    height: 30px;
    bottom: 12px;
    right: 7px;
    padding: 6px 3px 6px 3px;
    background-color: rgba(0, 0, 0, 0.281);
    font-size: 12px;
    color: #fff;
    font-weight: 300;
}

.gallery-car-image {
    position: relative;
    overflow: hidden;
    border-radius: 0;
}

.car-is-sold {
    position: absolute;
    right: -65px;
    padding: 0 55px;
    top: 20px;
    width: 200px;
    height: 33px;
    line-height: 33px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #980002;
    color: #fff !important;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.car-image {
    position: relative;
    width: 100%;
}

.card-compare-icon {

    display: none;
}

.card-compare-icon:hover {
    display: block;
    position: absolute;
    height: 30px;
    bottom: 12px;
    right: 7px;
    padding: 6px 3px 6px 3px;
    background-color: rgba(0, 0, 0, 0.281);
    font-size: 12px;
    color: #fff;
    font-weight: 300;
}

.our-car-price {
    color: #980002;
    font-weight: 600;
}

.traingle {
    width: 0;
    height: 0;
    background: transparent;
    border-top: 22px solid #C4BAB0;
    border-left: 22px solid #C4BAB0;
    border-right: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

.car-overlay-banner {
    background: #98000354;
    color: #fff;
    font-weight: 700;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    z-index: 9;
}

.our-car-item:hover .car-overlay-banner {
    opacity: 1;
    top: 0;
    height: 100%;

}

.our-car-item .special-car-list {
    background: #fff;
    color: #363636;
    overflow: hidden;
    bottom: 0;
    height: 0;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-out 0s;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    width: 100%;
    z-index: 9;
    text-align: center;
    border-color: #dddddd;
}

.our-car-item:hover .special-car-list {
    height: 17%;
    border-bottom: 1px solid #C4BAB0;
}

.car-img {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}

.car-list-content {
    background: rgb(255, 255, 255);

}

.car-details {
    padding-bottom: 15px;
}

.car-details ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.car-details ul li {
    margin-right: -1px;
    font-size: 13px;
    color: #5d5d5d;
    vertical-align: middle;
    display: inline-block;
    margin-top: 4px;
    margin-bottom: 0;
    min-width: 28%;


}

.car-details ul li img {
    margin-top: 0px;
    margin-left: 5px;


}

.car-detail {
    display: inline-block;
    min-width: 30%;
    margin-right: 5px;

}

.car-price {
    padding: 5px;
    position: relative;
    background-color: #B50004;
    color: #fff;
    font-size: 16px;
    width: 35%;
    text-align: right;
    font-family: "Helvetica Neue Medium";
}

.car-price:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: -12px;
    height: 100%;
    width: 17px;
    background-color: #B50004;
    -webkit-transform: skew(-14deg, 0deg);
    transform: skew(-14deg, 0deg);
    z-index: 5;
}

.car-card a {
    text-decoration: none;
    color: #000;
}

.car-card:hover a {
    text-decoration: none;
    color: #000;
}

.car-filter-panel {
    background-color: #fff;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    margin-bottom: 25px;
}

.criteria-title {
    padding: 15px 12px 15px 12px;
}

.criteria-lable {
    color: #B50004;
    font-weight: bold;
    font-size: 14px;
}

.criterias-lg {
    padding: 10px 12px 30px 12px;
}

.criterias-md {
    padding: 10px 12px 30px 12px;

}

.select-rado__control {
    background-color: #000 !important;
    border-color: #000 !important;
}

.select-rado__single-value {
    color: #fff !important;
}

.select-rado__menu {
    z-index: 3 !important;
}

.select-rado__menu-list {
    background-color: #B50004 !important;
}

.select-rado__option {
    color: #fff !important;
}

.select-rado__option:active {
    background-color: #000 !important;
}


/* ===================================
       Wagen page
======================================*/
.wagen-title {
    color: #000;
    font-size: 28px;
    font-family: "Helvetica Neue Medium";
    padding: 15px 0 5px 0;
    border-bottom: 1px solid #000;
}

.wagen-price {
    font-family: "Helvetica Neue Medium";
    font-size: 28px;
    color: #fff;
    padding: 15px 0 15px 0;
    background-color: #B50004;
}

.wagen-actions {
    padding: 15px 0;
}

.icon {
    display: inline-block;
    width: 25px;
    height: 15px;
    background-size: cover;
    background-repeat: no-repeat;
}

.icon-compare {
    background: url(/static/media/icon-compare.e69a5bc4.svg) no-repeat center;
}

.btn-gray:hover>.icon-compare {
    background: url(/static/media/icon-compare-white.8622a19e.svg) no-repeat center;
}

.icon-share {
    background: url(/static/media/icon-share.412c4c06.svg) no-repeat center;
}

.btn-gray:hover>.icon-share {
    background: url(/static/media/icon-share-white.82ab9141.svg) no-repeat center;
}

.icon-pdf {
    background: url(/static/media/icon-pdf.e89c93d0.svg) no-repeat center;
}

.btn-gray:hover>.icon-pdf {
    background: url(/static/media/icon-pdf-white.e89c93d0.svg) no-repeat center;
}

.share-icon {
    display: inline-block;
    margin-right: 15px;
    width: 22px;
    height: 22px;
}

.whatsapp {
    background: url(/static/media/icon-whatsapp.8760c84f.svg) no-repeat center;
}

.facebook {
    background: url(/static/media/icon-fb.9706e8ff.svg) no-repeat center;
}

.wagen-details {
    border-radius: 0;
    background-color: rgb(204, 204, 204, 0.5);
    padding: 0 15px;
}

.wagen-details>.list-group-item {
    border-bottom: 1px solid #4F4F4F !important;
    font-family: "Helvetica Neue Light" !important;
    font-size: 13px;
    color: #4F4F4F;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
}

.wagen-details>.list-group-item:last-child {
    border: 0 !important;
}

.wagen-detail-item {
    color: #B50004;
}

.wagen-detail-item-value {
    color: #000;
}

.wagen_is-sold {
    position: absolute;
    right: -55px;
    padding: 0 55px;
    top: 62px;
    width: 280px;
    height: 45px;
    line-height: 33px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background-color: #980002;
    color: #fff !important;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feature-box {
    border-radius: 0;
    background-color: rgb(204, 204, 204, 0.5);
    margin-bottom: 25px;
    padding: 16px;
    box-shadow: 0px 2px 5px #bdbdbd;
}

.feature-box h2 {
    font-weight: bold;
    font-size: 22px;
    color: #B50004;
    text-transform: uppercase;
    margin: 0 0 30px 0;
}

.feature-box ul {
    list-style: none;
    padding: 0 !important;

}

.feature-box li {
    overflow: hidden;
    color: #4F4F4F;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;

}

.alertSuccedSending>p {
    color: rgb(0, 121, 0);
    font-family: "Helvetica Neue Light";
    font-size: 14px;
    font-weight: bold;
}

.alertFaildSending>p {
    color: red;
    font-family: "Helvetica Neue Light";
    font-size: 14px;
    font-weight: bold;
}

.btn-share {
    position: relative;
}

.contat-info-form {
    font-family: "Helvetica Neue Light";
    font-size: 14px;
    font-weight: bold;
    color: #4F4F4F;
}

.contact-item {
    padding: 15px;
    font-family: "Helvetica Neue Light";
    font-size: 14px;
    font-weight: bold;
    color: #4F4F4F;
}

.contact-item p {
    color: #4F4F4F;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
}

.contact-item a {
    color: #4F4F4F;
}

.contat-info-form .form-control {
    border: 1px solid #4F4F4F;
}

.your-message {
    resize: none;
}

.slick-slide img {
    width: 100%;
}

.clientMessageBox {

    padding: 25px;
    margin-top: 30px;
    background-color: #363636;
}

.clientMessageBox>h2,
.clientMessageBox>p {
    color: #fff;
}

.compare-header {
    position: relative;
    display: inline-block;
}

.compare-header-icon {
    position: relative;

}

.list-badge {
    position: absolute;
    top: -6px;
    right: -7px;
    min-width: 18px;
    font-weight: 700;
    font-size: 11px;
    color: #fff;
    background-color: #B50004;
    padding: 0 2px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
}

.compareCell {
    border-bottom: 1px solid #000;
    border-left: 25px solid #fff;
    text-align: center;

}

.compareImageCell {
    border-left: 25px solid #fff;
}

.compareImageCell>img {
    width: 100%;
}

/*******************************************************************************************/
/*                           Sell your car                                                */
/*****************************************************************************************/
.content-Alert {
    color: #980002;
    font-weight: bold;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 575px) {
    .rado-top-nav {
        background-color: rgba(0, 0, 0, 0.9);
    }

    .navbar-toggler {
        border: 2px solid #980002;
        padding: 0.6rem;
        margin-right: 5px;
    }

    .navbar-brand>img {
        width: 150px;
        height: 30px;
    }

    .backToTop>img {
        width: 50% !important;
    }

    #home-cover {
        height: 100%;
        background: url(/static/media/bg-home-xs.f71be28c.jpg);
        width: 100%;
        background-repeat: no-repeat;
        background-position: top;
        background-color: #000;
        background-attachment: fixed;
    }

    .slogan {

        margin-top: 380px;
    }

    .slogan h3 {

        font-size: 21px;
    }

    .slogan h3 {

        font-size: 18px;
    }

    .call-us-via {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .whatsapp-call {
        height: 40px;
        width: 187px;
    }

    .call-icon img {
        width: 35px;
        height: 35px;
    }

    .call-ref>p {
        font-size: 12px;
    }

    .btn-general {

        padding: 4px 10px 4px 10px;
        font-size: 16px;
    }

    .btn-primary {
        font-size: 14px !important;
    }

    .btn-success {
        font-size: 14px !important;
    }

    .card-body {
        border-top: 5px solid #980002;
        background: linear-gradient(135deg, rgba(255, 255, 255, 1) 91%, #c4bab000 92%);
    }

    .content-title-black h1 {
        font-size: 30px;
    }

    .content-title-White h1 {
        font-size: 30px;
    }

    .criteria-title {
        background-color: #4F4F4F;
    }

    .criteria-lable {
        color: #fff;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 576px) {
    #home-cover {
        height: 100%;
        background: url(/static/media/bg-home-s.774c6e57.jpg);
        width: 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        background-attachment: fixed;

    }

    .rado-top-nav {
        background-color: rgba(0, 0, 0, 0.9);
    }

    .navbar-toggler {
        border: 2px solid #980002;
        padding: 0.6rem;
    }

    .slogan {

        margin-top: 126px;
    }

    .card-body {
        border-top: 5px solid #980002;
        background: linear-gradient(135deg, rgba(255, 255, 255, 1) 92%, #c4bab000 94%);
    }

    .btn-primary {
        font-size: 16px !important;
    }

    .btn-success {
        font-size: 16px !important;
    }

    .criteria-title {
        background-color: #4F4F4F;
    }

    .criteria-lable {
        color: #fff;
    }

    .navbar-brand {
        padding: 0.6rem !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
    .rado-top-nav {
        background-color: rgba(0, 0, 0, 0.9);
    }

    #home-cover {
        height: 100%;
        background: url(/static/media/bg-home-m.8f70b23c.jpg);
        width: 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        background-attachment: fixed;
    }

    .navbar-toggler {
        border: 2px solid #980002;
        padding: 0.6rem;
    }

    .slogan {
        margin-top: 200px;
    }

    .car-overlay-content>.list-group-item {
        padding-bottom: 0.2rem;
    }

    .card-body {
        border-top: 5px solid #980002;
        background: linear-gradient(135deg, rgba(255, 255, 255, 1) 90%, #c4bab000 90%);
    }

    .content-title-black h1 {
        font-size: 36px;
    }

    .content-title-White h1 {
        font-size: 36px;
    }

    .btn-primary {
        font-size: 18px !important;
    }

    .btn-success {
        font-size: 18px !important;
    }

    .criteria-title {
        background-color: #4F4F4F;
    }

    .criteria-lable {
        color: #fff;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
    .backToTop>img {
        width: 75%;
    }

    #home-cover {
        height: 100%;
        background: url(/static/media/bg-home-l.77f4ce04.jpg);
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        background-attachment: fixed;
    }

    .card-body {
        border-top: 5px solid #980002;
        background: linear-gradient(135deg, rgba(255, 255, 255, 1) 85%, #c4bab000 87%);
    }

    .content-title-black h1 {
        font-size: 36px;
    }

    .content-title-White h1 {
        font-size: 36px;
    }

    .criteria-title {
        background-color: #fff;
    }

    .criteria-lable {
        color: #980002;
    }

    .lf-sell-car-inner h2 {
        padding-top: 181px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
    .card-body {
        border-top: 5px solid #980002;
        background: linear-gradient(135deg, rgba(255, 255, 255, 1) 87%, #c4bab000 88%);
    }
}
